import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import type {
  TypedWrappedFieldProps,
  WrappedFieldInputProps,
} from "redux-form";
import { Field } from "redux-form";
import cs from "classnames";

import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { ServicesIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useAccountType } from "@js/apps/common/hooks";
import { useGetPostCategoriesQuery } from "@js/apps/give-and-get-help/api";
import { getPostCategoriesOptions } from "@js/apps/give-and-get-help/components/category-and-budget-utils";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components";
import { getFiltersStorageKey } from "@js/apps/jobs/apps/listing/utils";
import { Emoji } from "@js/components/emoji";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import { LocalStorage } from "@js/services";
import type { PostCategory } from "@js/types/give-and-get-help";

import { ServicesForm } from "../../config";

import styles from "./style.module.scss";

const DEFAULT_ROLE_LABEL = "1:1 Help services";

export const ServicesFilter = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [params] = useSearchParams();
  const { data } = useGetPostCategoriesQuery();
  const helpCategoryId = Number(params.get("help_category"));

  const label = getServiceLabel({
    selectedServiceId: helpCategoryId,
    services: data,
  });

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      label={label}
      title={DEFAULT_ROLE_LABEL}
      startIcon={<ServicesIcon />}
      isActive={!!helpCategoryId}
      popoverContent={
        <ServicesPopoverForm
          onSubmitSuccess={() => {
            setIsOpen(false);
          }}
        />
      }
    />
  );
};

type ServicesPopoverFormProps = {
  onSubmitSuccess: () => void;
};

const ServicesPopoverForm = ({ onSubmitSuccess }: ServicesPopoverFormProps) => {
  return (
    <ServicesForm
      onSubmitSuccess={onSubmitSuccess}
      form="listing-filters__services"
    >
      {({ submit }) => {
        return (
          <Field
            submit={submit}
            component={ServicesField}
            name="help_category"
          />
        );
      }}
    </ServicesForm>
  );
};

type ServicesFieldProps = TypedWrappedFieldProps<string> & {
  submit: () => void;
};
const ServicesField = ({ submit, input, meta }: ServicesFieldProps) => {
  const { data, isLoading } = useGetPostCategoriesQuery();
  const categoryOptions = getPostCategoriesOptions(data, false);
  const isSmall = useMediaQuery("sm");

  const handleReset = () => {
    input.onChange(null);
  };

  const handleApply = () => {
    submit();

    LocalStorage.removeItem(getFiltersStorageKey());
  };

  return (
    <JobPopoverFilterButton.Content
      onApply={handleApply}
      onReset={handleReset}
      singleValue
    >
      {(isLoading || !data?.length) && <Loader />}
      <ButtonSelectField
        input={input as WrappedFieldInputProps}
        meta={meta}
        multiple={false}
        options={categoryOptions}
        canSingleValueBeEmpty
        className="category-select-field"
        shape="squared"
        variant="white-violet"
        selectedVariant="medium-violet"
        fontWeight={500}
        typographySize={isSmall ? "small" : "medium"}
        size={isSmall ? "small" : "medium"}
      />
    </JobPopoverFilterButton.Content>
  );
};

type getServiceLabelArgsType = {
  selectedServiceId: number | undefined;
  services: PostCategory[] | undefined;
};

const getServiceLabel = ({
  selectedServiceId,
  services,
}: getServiceLabelArgsType) => {
  if (!selectedServiceId || !services) {
    return DEFAULT_ROLE_LABEL;
  }

  const selectedServiceOption = services.find(
    (serviceOption) => serviceOption.id === selectedServiceId,
  );

  return selectedServiceOption ? (
    <span
      className={cs(styles.label, {
        [styles.labelCustom]: SETTINGS.CUSTOM_EMOJIS.find(
          (item) => item.id === selectedServiceOption.emoji,
        ),
      })}
    >
      <Emoji emoji={selectedServiceOption.emoji} />
      <Typography component="span" size="small">
        {selectedServiceOption.name}
      </Typography>
    </span>
  ) : (
    DEFAULT_ROLE_LABEL
  );
};

export const useResetServicesFilterParamIfEmployer = () => {
  const [params, setSearchParams] = useSearchParams();
  const { isEmployer } = useAccountType();

  useEffect(() => {
    if (isEmployer && params.get("help_category")) {
      setSearchParams(
        (prevState) => {
          prevState.delete("help_category");
          return prevState;
        },
        { replace: true },
      );
    }
  }, [isEmployer, setSearchParams, params]);
};
