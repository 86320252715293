import React from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { UserAvatar } from "@js/components/user-avatar";
import type { NoteItem } from "@js/types/admin";
import type { User } from "@js/types/auth";
import { exactDateFromNow } from "@js/utils";

export type JobBidCommentProps = {
  comment: NoteItem;
  bidId: number;
  onDelete: (note: NoteItem, bidId: number) => void;
};

export const JobBidComment = ({
  comment,
  bidId,
  onDelete,
}: JobBidCommentProps) => {
  return (
    <Box className="job-bid-comment">
      <Box className="job-bid-comment__avatar">
        <UserAvatar
          user={comment.user as User}
          className="job-bid-comment__user-avatar"
          hideBorder
        />
      </Box>

      <Box className="job-bid-comment__details">
        <Typography component="p" variant="label" multipleEllipsis size="small">
          {`${comment.user.first_name} ${comment.user.last_name}`}
        </Typography>
        <Typography
          component="p"
          size="small"
          sx={{ whiteSpace: "nowrap" }}
          color="grey"
        >
          {exactDateFromNow(comment.created)}
        </Typography>
      </Box>
      <Box className="job-bid-comment__content">
        <Typography component="p" hyphens size="small">
          {comment.content}
        </Typography>
      </Box>

      {comment.can_user_delete && (
        <Button
          sx={{
            marginBottom: "auto",
            marginLeft: { sm: "initial", xs: "auto" },
          }}
          size="x-small"
          variant="transparent-blue"
          onClick={() => onDelete(comment, bidId)}
        >
          Delete
        </Button>
      )}
    </Box>
  );
};
