import { useCallback } from "react";

import { fetchCurrentUser } from "@js/apps/auth/actions";
import { jobsApi } from "@js/apps/jobs/api";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import type { SchedulingFormData } from "../../forms";
import { BidSendSchedulingLinkForm } from "../../forms";
import { openSendScheduleLinkConfirmationModal } from "../../forms/bid-send-scheduling-link/send-calendar-link-confirmation-modal";
import { useSendSchedulingLink } from "../../hooks/use-send-scheduling-link";

type OpenSendSchedulingLinkModalProps = {
  bid: EmployerBidListBid;
  job: Job;
};

export const SendSchedulingLinkModalContent = ({
  bid,
  job,
}: OpenSendSchedulingLinkModalProps) => {
  const dispatch = useAppDispatch();
  const { ...formProps } = useSendSchedulingLink({ bid, job });

  const onSubmit = useCallback(
    (values: SchedulingFormData) => {
      formProps
        .onSubmit(values)
        .then(() => {
          dispatch(fetchCurrentUser());
          dispatch(jobsApi.util.invalidateTags([{ type: "Jobs", id: job.id }]));
          ModalInstance.close();
          openSendScheduleLinkConfirmationModal();
        })
        .catch(() => {
          Snackbar.error("Something went wrong...");
        });
    },
    [dispatch, formProps, job],
  );

  return <BidSendSchedulingLinkForm {...formProps} onSubmit={onSubmit} />;
};

export const openSendSchedulingLinkModal = (
  props: OpenSendSchedulingLinkModalProps,
) => {
  ModalInstance.open({
    className: "scheduling-modal text-content-mw",
    children: <SendSchedulingLinkModalContent {...props} />,
    keepOnBackdropClick: true,
    closeButton: true,
    closeButtonProps: {
      sx: {
        backgroundColor: "var(--grey-5) !important",
        height: "40px",
        width: "40px",
      },
      size: "x-small",
    },
  });
};
