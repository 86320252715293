import type { WrappedFieldProps } from "redux-form";
import { change } from "redux-form";

import { Box, Switch, Typography } from "@hexocean/braintrust-ui-components";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { FieldSectionPanel } from "@js/apps/jobs/forms/fields";
import { useAIRInterview } from "@js/apps/jobs/hooks/use-air-interview";
import { useAppDispatch } from "@js/hooks";

import { CREATE_JOB_FORM_ID, EDIT_JOB_FORM_ID } from "../../constants";

export const AiInterviewSection = ({
  input,
  isEditForm,
}: {
  isEditForm?: boolean;
} & WrappedFieldProps) => {
  const { showAIRInterview } = useAIRInterview();
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    if (!showAIRInterview) {
      dispatch(
        change(
          isEditForm ? EDIT_JOB_FORM_ID : CREATE_JOB_FORM_ID,
          "is_ai_interview_enabled",
          false,
        ),
      );
    }
  });

  if (!showAIRInterview) {
    return null;
  }

  return (
    <>
      <AiSectionHeader />
      <Box marginTop="-16px">
        <FieldSectionPanel
          title="AI interview"
          description="Innovate your hiring process with AI and find the right candidates faster than ever."
        >
          <Switch
            checked={!!input?.value}
            onClick={input?.onChange}
            label={
              <Box display="flex" gap={1} alignItems="center">
                <Typography component="span" size="medium" fontWeight={500}>
                  {input?.value
                    ? "AI interviewing enabled"
                    : "Enable AI interviewing"}
                </Typography>
                {input?.value && (
                  <img
                    src={`${SETTINGS.STATIC_URL}jobs/ai-job-icon-star.svg`}
                    alt="ai star"
                    width={20}
                    height={20}
                    className="ai-interview-section-switch-label-icon"
                  />
                )}
              </Box>
            }
          />
          <Typography component="p" mt={2} size="medium" fontWeight={400}>
            Our AI will conduct initial interviews based on the criteria you
            set, saving you time and ensuring a consistent interview experience
            for all candidates.
          </Typography>
          <Typography component="p" mt={2} size="small" fontWeight={400}>
            You’ll be able to edit the questions and grading criteria after
            posting this job.
          </Typography>
        </FieldSectionPanel>
      </Box>
    </>
  );
};

const AiSectionHeader = () => (
  <Box className="ai-interview-section-header">
    <Box display="flex" flexDirection="row" gap={2} alignItems="center">
      <img
        src={`${SETTINGS.STATIC_URL}jobs/ai-icon.svg`}
        alt="ai job"
        height={31}
        width={29}
      />
      <Typography
        component="span"
        fontWeight={500}
        size="small"
        sx={{
          color: "var(--dark-violet)",
          border: "2px solid var(--medium-violet)",
          borderRadius: "35px",
          padding: "2px 12px",
          backgroundColor: "var(--soft-violet)",
          whiteSpace: "nowrap",
        }}
      >
        AI Interview
      </Typography>
    </Box>
    <Typography component="span" fontWeight={400} size="small">
      Speed up your interviewing process by 2x by generating an AI interview
    </Typography>
  </Box>
);
