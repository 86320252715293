import { fetchCurrentUser } from "@js/apps/auth/actions";
import { ModalInstance } from "@js/components/modal";
import type { AppDispatch } from "@js/store";

import { BoostSuccessModal } from "./boost-success-modal-content";

export const openBoostSuccessModal = (
  dispatch: AppDispatch,
  onClose?: () => void,
): void => {
  ModalInstance.open({
    padding: false,
    keepOnBackdropClick: true,
    children: <BoostSuccessModal />,
    onClose: () => {
      dispatch(fetchCurrentUser({ refetchInOtherTabs: true }))
        .catch(() => null)
        .finally(() => onClose?.());
    },
  });
};
