import { useCallback, useRef } from "react";
import cs from "classnames";

import { Box } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useUser } from "@js/apps/common/hooks";
import { CreatePostModalInstance } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { openCreatePostModal } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/open-modal";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import { openEmailConfirmationModal } from "@js/apps/onboarding/components/sign-up-confirmation-content";
import { UserAvatar } from "@js/components/user-avatar";
import { useAppDispatch } from "@js/hooks";

import { useShouldShowCreatePostFAB } from "../../hooks";
import { useDisablePostActions } from "../../hooks/use-disable-post-actions";

import { PostBtn, PostTextField } from "./components";
import { CREATE_POST_PROMPT } from "./constants";
import { CreatePostFAB } from "./create-post-fab";
import { useOpenCreatePostModalFromParams } from "./hooks";

type CreatePostSectionProps = {
  hideActionButtons?: boolean;
  showCreatePostFAB?: boolean;
};

export const CreatePostSection = ({
  hideActionButtons = false,
  showCreatePostFAB,
}: CreatePostSectionProps) => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const isMobile = useMediaQuery(650);
  const isTablet = useMediaQuery(1040);
  const postLocation = usePostLocationContext();
  const disablePostActions = useDisablePostActions();

  const handleOpenCreatePostModal = useCallback(() => {
    if (user?.is_verified) {
      dispatch(openCreatePostModal({ composer_location: postLocation }));
    } else {
      openEmailConfirmationModal();
    }
  }, [dispatch, postLocation, user?.is_verified]);

  useOpenCreatePostModalFromParams(handleOpenCreatePostModal);

  const createPostSectionRef = useRef<HTMLDivElement | null>(null);
  const shouldShowCreatePostFAB = useShouldShowCreatePostFAB(
    !!showCreatePostFAB,
    createPostSectionRef,
  );

  if (!user) return <></>;

  return (
    <>
      <Box className="create-post-section" ref={createPostSectionRef}>
        <Box
          className={cs("create-post-section__top-section", {
            ["create-post-section__top-section--disabled"]: disablePostActions,
          })}
        >
          <UserAvatar user={user} hideBadge disableShadow hideBorder />

          <PostTextField
            createPostPrompt={isMobile ? "Ask for help..." : CREATE_POST_PROMPT}
            onClick={handleOpenCreatePostModal}
            disabled={disablePostActions}
            isMobile={isMobile}
            isTablet={isTablet}
            hideActionButtons={hideActionButtons}
          />

          <PostBtn
            onClick={handleOpenCreatePostModal}
            isMobile={isMobile}
            disabled={disablePostActions}
          />
        </Box>
      </Box>
      {shouldShowCreatePostFAB && (
        <CreatePostFAB disabled={disablePostActions} />
      )}

      <CreatePostModalInstance />
    </>
  );
};
