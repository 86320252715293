import React from "react";

import { Box, Loader } from "@hexocean/braintrust-ui-components";
import { AddCommentForm } from "@js/apps/jobs/forms/add-comment";

import { useBidComments } from "../../hooks/bid-comments";

import { JobBidComment } from "./job-bid-comment";

export const ADD_COMMENT_FORM_ID = "add-comment-form";

export type JobBidCommentListProps = {
  id: number;
};

export const JobBidCommentList = ({ id }: JobBidCommentListProps) => {
  const { isLoading, comments, deleteComment, onSubmit, onSubmitSuccess } =
    useBidComments({ bidId: id });

  return (
    <>
      <AddCommentForm
        bidId={id}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
      />

      {!comments || isLoading ? (
        <Loader />
      ) : (
        <Box className="job-bid-comment-list">
          {comments.map((comment) => {
            return (
              <JobBidComment
                bidId={id}
                key={comment.id}
                comment={comment}
                onDelete={deleteComment}
              />
            );
          })}
        </Box>
      )}
    </>
  );
};
